export function toUnquotedJSON(param) {
  if (Array.isArray(param)) {
    const results = new Array<any>();

    for (const elem of param) {
      results.push(toUnquotedJSON(elem));
    }

    return "[" + results.join(",") + "]";
  }

  if (param && typeof param === "object") {
    const props = Object.keys(param)
      .map((key) => {
        let safeKey = key;

        if (key.match(/^[a-zA-Z_$][a-zA-Z\d_$]*$/) === null) safeKey = `"${key}"`;

        return `${safeKey}: ${toUnquotedJSON(param[key])}`;
      })
      .join(",");

    return `{${props}}`;
  }

  return JSON.stringify(param);
}

export function parseJsonWithBuffer(json) {
  return JSON.parse(json, (_key, value) => {
    if (value && typeof value === "object" && value.type === "Buffer" && Array.isArray(value.data)) {
      return Buffer.from(value.data);
    }

    return value;
  });
}
