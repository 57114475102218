import { Injectable } from "@angular/core";
import { E_Patient_Actions_Type } from "@backend/graph/patient_actions/patient-action-base";
import { FORMATS } from "@shared/constants";
import { BehaviorSubject } from "rxjs";
import { Constants } from "src/constants";
import { SubSink } from "subsink";
import { CacheService } from "./cache.service";
import { GAService } from "./ga.service";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import { LoggerService } from "./logger.service";
import { NavigationService } from "./navigation.service";
import { NotificationService, NotificationTypes } from "./notification.service";
import { PatientsService } from "./patients.service";
import { PipLoginService } from "./pip-login.service";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { PortalInPracticeService } from "./portal-in-practice.service";
import { E_ActionType } from "@backend/graph/patients/patient-base";
import { AnalyticsService, METRIC } from "./analytics.service";
import { PatientNhsPrBase } from "@backend/graph/patient_nhs_pr/patient-nhs-pr-base";
import { ExemptionOption } from "@backend/graph/patient_nhs_pr/patient-nhs-pr-exemptions/exemption-options";
import { CreatePatientNhsPrFormBase } from "@backend/graph/patient_nhs_pr/create-patient-nhs-pr/create-patient-nhs-pr-base";
import { PatientActionsEntry } from "src/app/data_model/patient-actions";

dayjs.extend(advancedFormat);

export interface I_NHS_PR {
  status: "LOADING" | "LOADED" | "SAVED";
  data?: PatientNhsPrBase;
}

const GRAPHQL_QUERY = (languageCode: string) => {
  const args = `${languageCode ? `language_code: "${languageCode}"` : ""}`;

  return `{
  patient {
    date_of_birth
    nhs_pr(${args}) {
      meta_data {
        treatment_plans {
          total_cost
        }
        appointments {
          appointment_sid
          practitioner_name
        }
      }
      form_type
      form_required
      legal_text {
        FP_17_BASIC
        FP_17_EXEMPTION_DEFAULT
        FP_17_PATIENT_RESPONSIBILITY
        GP_17_EXEMPTION_DEFAULT
      }
      patient_exemption {
        exemption_code
        updated_at
        description
      }
      exemption_options {
        exemption_code
        description
        intro
        group
        details {
          title
          intro
          fields {
            field
            label
            type
            placeholder
            more_info
            validation {
              pattern
              date {
                type
                value
              }
              groupId
              required
              number {
                min
                max
              }
            }
          }
        }
        legal_text
      }
      display_groups {
        id
        intro
      }
      language_codes
      language_code
      updated_at
    }
  }
}`;
};

export interface I_LanguageSwitch {
  buttonText: string;
  show: boolean;
  language_code: string;
  switch_to_language_code: string;
  switch_to_buttonText: string;
}

@Injectable({
  providedIn: "root",
})
export class NhsPrService {
  private _subs = new SubSink();
  public new_pr_form: CreatePatientNhsPrFormBase = {};
  public selected_exemption: ExemptionOption = {};
  public pr_data: PatientNhsPrBase | null = null;
  public onPrDataChanged: BehaviorSubject<I_NHS_PR | null> = new BehaviorSubject(null);
  public langSwitch: I_LanguageSwitch = {
    buttonText: "Cymraeg",
    show: false,
    language_code: "en",
    switch_to_language_code: "wa",
    switch_to_buttonText: "English",
  };
  private _isFetching = false;
  private _formUrl: string;
  constructor(
    private _httpService: HttpService,
    private _navigationService: NavigationService,
    private _notificationService: NotificationService,
    private _gaService: GAService,
    private _loggerService: LoggerService,
    private _patientsService: PatientsService,
    private _cacheService: CacheService,
    private _pipLoginService: PipLoginService,
    private _portalInPracticeService: PortalInPracticeService,
    private _jwtService: JWTService,
    private _analyticsService: AnalyticsService
  ) {}

  public get formUrl(): string {
    return this._formUrl;
  }

  getPrData(force?: boolean, language_code = "en"): void {
    if (this._isFetching) {
      const interval = setInterval(() => {
        if (!this._isFetching) {
          clearInterval(interval);
          return;
        }
      }, 300);
      return;
    }
    if (this.pr_data && !force) {
      this.onPrDataChanged.next({
        status: "LOADED",
        data: this.pr_data,
      });
    } else {
      this._isFetching = true;
      this._subs.sink = this._httpService.query(GRAPHQL_OPERATION_NAMES.NHS_PR, GRAPHQL_QUERY(language_code)).subscribe(
        (response) => {
          const pr_data: PatientNhsPrBase = {
            age: response.data.patient.age,
            date_of_birth: response.data.patient.date_of_birth,
            ...response.data.patient.nhs_pr,
          };

          if (pr_data.patient_exemption?.exemption_code) this.selected_exemption = pr_data.patient_exemption;
          if (!pr_data.patient_exemption?.description) delete pr_data.patient_exemption;

          this.pr_data = pr_data;
          this.langChecker();
          this.onPrDataChanged.next({
            status: "LOADED",
            data: pr_data,
          });
          this._isFetching = false;
        },
        () => {
          this._isFetching = false;
          this.saveError();
        }
      );
    }
  }

  public get isPatientVerificationRequired(): boolean {
    if (!this._jwtService.isPatientUnauthenticated()) return false;

    return !this._patientsService.patientInfo?.date_of_birth || !this._patientsService.patientInfo?.last_name;
  }

  public get patientResponsibilityLegalText(): string {
    const legalText = this.pr_data?.legal_text;

    if (legalText && "FP_17_PATIENT_RESPONSIBILITY" in legalText) {
      return legalText.FP_17_PATIENT_RESPONSIBILITY;
    }

    return "";
  }

  public getLegalText(): Array<string> {
    const legalText: Array<string> = [];

    if (!this.pr_data?.legal_text) return legalText;

    const legalTextArray = this.pr_data.legal_text;

    if (this.selected_exemption?.legal_text?.length) {
      this.selected_exemption.legal_text.forEach((i) => {
        legalText.push(legalTextArray[i] || "");
      });
    } else {
      Object.values(legalTextArray).forEach((text: string) => {
        if (text) legalText.push(text);
      });
    }

    return legalText;
  }

  changeLanguage() {
    this.langSwitch.buttonText = this.langSwitch.switch_to_buttonText;
    this.getPrData(true, this.langSwitch.switch_to_language_code);
  }

  saveSuccess() {
    const isPipShortcut = this._pipLoginService.hasPipShortcut;
    this._gaService.action("nhs_pr_exemption_sign_success");
    if (isPipShortcut) {
      this._pipLoginService.navigate("thank-you");
      return;
    }
    this._navigationService.navigate("/my-dental");
  }

  saveError() {
    this._navigationService.navigate("/my-dental/nhs-pr/terms");
    this._notificationService.open({
      type: NotificationTypes.ERROR,
      title: "There was an unexpected error, please try again",
    });
    this._gaService.action("nhs_pr_exemption_sign_error");
    try {
      this._loggerService.error("nhs_pr_exemption_sign_error");
    } catch (err) {}
  }

  private get _actionsSession(): Record<string, any> {
    return JSON.parse(this._cacheService.getSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY) || "{}");
  }

  private _isActionRequired(action: PatientActionsEntry): boolean {
    return action.type === E_Patient_Actions_Type.NHS_PR && action.required;
  }

  public get action(): PatientActionsEntry | undefined {
    return this._actionsSession?.actions?.find((i) => this._isActionRequired(i));
  }

  private _updateAction(): void {
    const actionsSession = this._actionsSession;
    const requiredAction = actionsSession?.actions?.find((action) => this._isActionRequired(action));

    if (!requiredAction || !this.formUrl) return;

    requiredAction.form_url = this.formUrl;
    this._cacheService.setSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY, JSON.stringify(actionsSession));
  }

  private _trackSigned() {
    const referrer = this._jwtService.referrerAsJson;
    const { site_id } = this._jwtService.getJWT();
    if (this.action) {
      const { pms_action_type } = this.action;
      this._analyticsService.track(new METRIC.ActionSigned_NhsPr(site_id, referrer, pms_action_type));
    }
  }

  public signForm(): void {
    this._trackSigned();
    this.onPrDataChanged.next({
      status: "LOADING",
    });

    const mutation = this._getGraphQlMutation();
    this._httpService.mutation("createPatientNhsPrExemption", mutation).subscribe((response) => {
      try {
        if (!response.errors?.length) {
          this.onPrDataChanged.next({
            status: "SAVED",
          });
          this.new_pr_form = {};
          // form signing is handled on a job queue in the PMS, so if we request pr data again here, it may show as
          // still being required for a short period. So instead, just set the form required to false so that we don't get weird behaviour
          this.pr_data = { ...this.pr_data, form_required: false } as PatientNhsPrBase;
          this._formUrl = response.data.createPatientNhsPrExemption.url;
          this._updateAction();

          this.saveSuccess();

          if (this.new_pr_form.date_of_birth && this._patientsService.patientInfo && !this._patientsService.patientInfo?.date_of_birth) {
            this._patientsService.patientInfo.date_of_birth = this.new_pr_form.date_of_birth;
          }
        } else {
          throw response.errors[0].message;
        }
      } catch (err) {
        this._handleCreateError(err);
      }
    });
  }

  public get isScotland(): boolean {
    return !!this._patientsService.isNHSScotland;
  }

  private _handleCreateError(error?: string) {
    if (error === "NOT_REQUIRED__ALREADY_SIGNED") {
      this._gaService.action("nhs_pr_NOT_REQUIRED__ALREADY_SIGNED");
      this._backToForm();
    } else if (error === "INCORRECT_DOB") {
      this.new_pr_form.date_of_birth = undefined;
      this.saveError();
      this._backToForm();
    } else {
      this.getPrData(true);
      this.saveError();
    }
  }

  private _formatFields(fields: string[], data: Record<string, any>): string {
    return fields
      .map((key) => data[key] && `${key}: "${this._formatValue(key, data[key])}"`)
      .filter(Boolean)
      .join(",");
  }

  private _formatValue(key: string, value: any): string {
    return key === "date_of_birth" ? dayjs(value, FORMATS.LOCALE_DATE).format("YYYY-MM-DD") : value;
  }

  private get _benefitRecipientFields(): Array<string> {
    return ["ni_number", "hc2_number", "hc3_number", "name", "date_of_birth"];
  }

  private _generateBenefitRecipientArgs(): string {
    return this._formatFields(this._benefitRecipientFields, this.new_pr_form.patient_exemption || {});
  }

  private _generateProxySignerArgs(): string {
    return this._formatFields(Object.keys(this.new_pr_form.proxy_signer || {}), this.new_pr_form.proxy_signer || {});
  }

  private _getGraphQlMutation(): string {
    const benefitRecipientArgs = this._generateBenefitRecipientArgs();
    const proxySignerArgs = this._generateProxySignerArgs();

    const patientExemption = Object.entries(this.new_pr_form.patient_exemption || {})
      .filter(([key, value]) => !this._benefitRecipientFields.includes(key) && value)
      .map(([key, value]) => `${key}: "${value}"`);

    if (benefitRecipientArgs) {
      patientExemption.push(`benefit_recipient: {${benefitRecipientArgs}}`);
    }

    const siteId = this._jwtService.isPip() ? this._portalInPracticeService.siteId : undefined;

    return `{
      createPatientNhsPrExemption(new_item: {
        ${this.langSwitch.language_code ? `language_code: "${this.langSwitch.language_code}",` : ""}
        ${this.new_pr_form.practitioner_registration_id ? `additional_fields: { value: "${this.new_pr_form.practitioner_registration_id}" }` : ""}
        signature_used: ${this.new_pr_form.signature_used},
        date_of_birth: "${this.new_pr_form.date_of_birth}",
        patient_exemption: {
          ${patientExemption.join(",\n")}
        },
        ${proxySignerArgs ? `proxy_signer: {${proxySignerArgs}},` : ""}
        encoded_signature: "${this.new_pr_form.encoded_signature}"
        ${siteId ? `site_id: "${siteId}"` : ""}
      }) {
        error
        url
      }
    }`;
  }

  private _backToForm(): void {
    this._navigationService.navigate("/my-dental/nhs-pr");
  }

  langChecker() {
    if (this.pr_data?.language_codes?.length === 2) {
      if (this.pr_data.language_code) this.langSwitch.language_code = this.pr_data.language_code;

      switch (this.pr_data.language_code) {
        case "en":
          if (this.pr_data.language_codes.indexOf("cy") > -1) {
            this.langSwitch.buttonText = "Cymraeg";
            this.langSwitch.switch_to_buttonText = "English";
            this.langSwitch.switch_to_language_code = "cy";
            this.langSwitch.show = true;
          }
          break;
        case "cy":
          if (this.pr_data.language_codes.indexOf("en") > -1) {
            this.langSwitch.buttonText = "English";
            this.langSwitch.switch_to_buttonText = "Cymraeg";
            this.langSwitch.switch_to_language_code = "en";
            this.langSwitch.show = true;
          }
          break;
        default:
          break;
      }
    } else {
      this.langSwitch.show = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public generateDefaultRoute(form_type: E_ActionType | undefined, exemption_code: string | undefined): string {
    if (form_type === E_ActionType.GP17Part2 && exemption_code && !this._canPatientChangeExemption(form_type, exemption_code)) {
      return "/my-dental/nhs-pr/terms";
    }
    return "/my-dental/nhs-pr/form";
  }

  private _canPatientChangeExemption(form_type: E_ActionType, exemption_code: string): boolean {
    const patient_not_fully_exempt_codes = ["0", "H3"];
    return form_type === E_ActionType.GP17Part2 && patient_not_fully_exempt_codes.includes(exemption_code) && this.isScotland;
  }
}
