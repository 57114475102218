export enum E_ManageFeatures {
  BLOCK_MULTIPLE_LOGINS = "block-multiple-manage-logins",
  CUSTOM_FAVICON = "custom-favicon",
  MULTISITE_EMAIL_ADDRESS = "multisite-email-address",
  ONLINE_SIGNING_AUTO_SWITCH_ON = "online-signing-auto-switch-on",
  LOGOUT_ON_INACTIVITY = "logout-on-inactivity-manage",
}

export enum E_PatientFeatures {
  AUTOFILL_NEW_PATIENT_FORM = "autofill-new-patient-form",
  BLOCK_MULTIPLE_LOGINS = "block-multiple-patient-logins",
  CAPTURE_INSURANCE_DETAILS = "capture-insurance-details",
  CHAT_BOT = "chat-bot",
  CONFIRM_APPOINTMENTS_AFTER_FORM_UPDATE = "confirm-appointments-after-form-update",
  EMAIL_SENDING_VIA_PMS = "email-sending-via-pms",
  EMERGENCY_CONTACT = "emergency-contact",
  GP17 = "gp17",
  MH_QUESTION_AI_ANALYSIS = "mh-question-ai-analysis",
  LOGOUT_ON_INACTIVITY = "logout-on-inactivity-patient",
  PATIENT_GP_DETAILS = "patient-gp-details",
  RECAPTCHA = "recaptcha",
  SEND_COUNTRY_CODE_AS_STRING = "send-country-code-as-string",
  SLOT_VARIANTS = "slot-variants",
  USE_PMS_ACTIONS_FOR_FP17_AND_GP17 = "use-pms-actions", //renamed to make for accuracy, but not changing the value
}

export type E_Features = E_ManageFeatures | E_PatientFeatures;

export enum E_SlotVariantFeature {
  FULL = "full",
  SINGLE = "single",
  MULTIPLE_SAME_DAY = "multiple-same-day",
  MULTIPLE_DIFFERENT_DAY = "multiple-different-day",
}
